// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-en-js": () => import("./../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-registration-js": () => import("./../src/pages/en/registration.js" /* webpackChunkName: "component---src-pages-en-registration-js" */),
  "component---src-pages-fa-js": () => import("./../src/pages/fa.js" /* webpackChunkName: "component---src-pages-fa-js" */),
  "component---src-pages-fa-registration-js": () => import("./../src/pages/fa/registration.js" /* webpackChunkName: "component---src-pages-fa-registration-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

